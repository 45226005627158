import { type loader as RootLoader } from "#app/root";
import { Button } from "#app/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "#app/components/ui/dropdown-menu";
import { cn } from "#app/utils/misc.tsx";
import { type IconName } from "@/icon-name";
import { Form, NavLink, useFetcher, useNavigate, useRouteLoaderData } from "@remix-run/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "./ui/icon.tsx";

interface NavItemProps {
  to: string;
  label: string;
  iconName?: IconName;
}

const NavItem: React.FC<NavItemProps> = ({ to, iconName, label }) => {
  return (
    <NavLink
      to={to}
      unstable_viewTransition
      className={({ isActive, isPending }) =>
        cn(
          isPending && "cursor-none",
          isActive ? "text-[#9D21E6]" : "text-gray-400",
          "flex flex-col justify-arround items-center"
        )
      }
    >
      {iconName ? (
        <Icon name={iconName} stroke="" className="text-3xl md:text-4xl" />
      ) : (
        <img src="/static/images/logokedi.svg" alt="Logo Kedi" className="h-[30px] md:h-[36px]" />
      )}
      <span className="text-sm font-medium">{label}</span>
    </NavLink>
  );
};

const NavigationMenu = ({ className }: { className: string }) => (
  <div className={className}>
    <NavItem to="/" iconName="home" label="Home" />
    <NavItem to="/dashboard" iconName="assessment" label="Dashboard" />
    <NavItem to="/messages" label="Kelas" />
    <NavItem to="/calendar" iconName="date-range" label="Kalendar" />
    <NavItem to="/market" iconName="storefront" label="Market" />
  </div>
);

const Logo = () => (
  <React.Fragment>
    <NavLink to="/" unstable_viewTransition className="hidden sm:inline relative max-w-[208px]">
      <img
        src="/static/images/logomain.svg"
        style={{ width: 208, height: 66 }}
        width={208}
        height={66}
        className="w-auto h-auto"
        alt="Logo Kedi"
      />
    </NavLink>
    <NavLink
      to="/"
      unstable_viewTransition
      className="inline sm:hidden  max-h-[45px] max-w-[150px]"
    >
      <img
        src="/static/images/logotext.svg"
        width="0"
        height="0"
        sizes="100vw"
        className="pt-2 w-full h-auto"
        alt="Logo Kedi"
      />
    </NavLink>
  </React.Fragment>
);

function LanguageDropDown() {
  const { t, i18n } = useTranslation();
  const fetcher = useFetcher();

  const onValueChange = (lang: string) => {
    i18n.changeLanguage(lang);
    fetcher.submit(null, {
      method: "POST",
      action: `/settings/change-language/${lang}`,
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          className="uppercase sm:px-4 sm:py-2 px-2 py-0 sm:border border-0"
        >
          <Icon name="language" size="md" className="sm:flex hidden text-gray-600">
            {i18n.language}
          </Icon>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-[40px]">
        <DropdownMenuRadioGroup value={i18n.language} onValueChange={onValueChange}>
          <DropdownMenuRadioItem value="id">Indonesia</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="en">English</DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

const ProfileIcon = () => {
  const { user } = useRouteLoaderData<typeof RootLoader>("root") || {};
  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex-none order-1 sm:order-none h-[36px] sm:h-[40px] w-[36px]  sm:w-[40px] rounded-lg relative outline-none">
        <img
          width={60}
          height={60}
          src={user?.photo ? user?.photo : "/static/images/logokedi.svg"}
          className="absolute inset-0 h-full w-full object-cover  rounded-lg  shadow-md"
          alt=""
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-8">
        <DropdownMenuLabel className="text-center">Menu Profile</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem className="hover:bg-purple-100" asChild>
          <Form
            method="get"
            action={`/profile/${user?.id}`}
            className="flex w-full items-center gap-1.5 rounded-md p-2 text-sm hover:bg-purple-100"
            role="menuitem"
          >
            <button type="submit" className="flex w-full items-center gap-1.5 rounded-md text-sm">
              <Icon name="avatar" size="md">
                Profile
              </Icon>
            </button>
          </Form>
        </DropdownMenuItem>
        <DropdownMenuItem className="hover:bg-purple-100" asChild>
          <Form
            method="post"
            action={`/logout`}
            className="flex w-full items-center gap-1.5 rounded-md p-2 text-sm hover:bg-purple-100"
            role="menuitem"
          >
            <button type="submit" className="flex w-full items-center gap-1.5 rounded-md text-sm">
              <Icon name="logout" size="md">
                Log Out
              </Icon>
            </button>
          </Form>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const SearchMenu = () => {
  return (
    <NavLink
      to="/search"
      className="order-0 sm:order-none flex relative justify-start max-w-[150px]"
    >
      <div className="inline ">
        <Icon name="search" size="lg" className="text-gray-600" />
      </div>
    </NavLink>
  );
};

import { Tabs, TabsContent, TabsList, TabsTrigger } from "#app/components/ui/tabs";
import { Payment, SkeltonList } from "#app/routes/resources+/finance";
import { ClientOnly } from "remix-utils/client-only";
import { ViewportProvider } from "#app/components/client/viewport.client";

const classTabTrigger =
  "bg-[#EAE9E9] text-[#302B2B] rounded-lg data-[state=active]:bg-[#D7B9FD] data-[state=active]:text-[#45008E]";

const NotifIcon = () => {
  const [value, setValue] = React.useState("contacts");
  const { user } = useRouteLoaderData<typeof RootLoader>("root") || {};
  const notifMessage: [] | null = null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="order-1 sm:order-none  rounded-lg relative outline-none">
        {notifMessage?.filter((d: any) => d.publisher !== user?.uuid).length > 0 && (
          <span className="absolute py-0.5 px-1.5 bg-green-400 rounded-full -top-1 right-1 border-2 border-white text-sm font-medium">
            {notifMessage?.filter((d) => d.publisher !== user?.uuid).length}
          </span>
        )}
        <Icon name="notifications" size="lg" className="text-gray-600" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[90vw] sm:w-[500px] mx-5 sm:mr-8">
        <Tabs value={value} onValueChange={setValue}>
          <TabsList className="m-2 grid grid-cols-2 gap-2">
            <TabsTrigger className={classTabTrigger} value="class">
              Notifikasi
            </TabsTrigger>
            <TabsTrigger className={classTabTrigger} value="contacts">
              Status Transaksi
            </TabsTrigger>
          </TabsList>
          <TabsContent
            forceMount
            hidden={value !== "class"}
            value="class"
            className="h-[60vh] overflow-y-auto px-1.5 space-y-2 pt-1.5 border rounded-lg mx-2 mb-2 mt-3 shadow-sm"
          >
            {/*<NotifChat
              user={user}
              notifMessage={notifMessage}
              notifDelete={notifDelete}
            />*/}

            <div className="grid h-full place-content-center px-4">
              <div className="text-center">
                <h1 className="text-6xl font-black text-gray-200">Progress</h1>

                <p className="mt-4 text-gray-500">this menu is on proggres</p>
              </div>
            </div>
          </TabsContent>
          <TabsContent
            forceMount
            hidden={value !== "contacts"}
            value="contacts"
            className="px-1.5 pb-4"
          >
            <ClientOnly fallback={<SkeltonList />}>
              {() => (
                <ViewportProvider>
                  <Payment isNotif={true} />
                </ViewportProvider>
              )}
            </ClientOnly>
          </TabsContent>
        </Tabs>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const Navbar = () => {
  return (
    <header>
      <div id="debug-development" />
      <nav className="fixed top-0 z-20 bg-white h-[60px] sm:h-[70px]  items-center w-full grid grid-cols-2 md:grid-cols-3 gap-5  px-4 sm:px-6 border-b shadow-md pb-0.5">
        <Logo />
        <NavigationMenu className="hidden md:flex items-center justify-center gap-x-3 lg:gap-x-5" />
        <div className="flex items-center justify-end gap-x-5">
          <div className="flex items-center gap-x-3">
            <SearchMenu />
            <NotifIcon />
            <LanguageDropDown />
            <ProfileIcon />
          </div>
        </div>
      </nav>
      <nav className="md:hidden fixed bottom-0 left-0 z-50 w-full h-[60px] bg-white border-t border-gray-200 ">
        <NavigationMenu className="grid h-full max-w-lg grid-cols-5 mx-auto font-medium pt-1.5" />
      </nav>
      <div className="h-[62px] sm:h-[72px]" />
    </header>
  );
};
