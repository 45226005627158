import { parseTimetoken } from "#app/lib/converter.ts";
import { parseIfString } from "#app/lib/helper.ts";
import { type loader as RootLoader } from "#app/root";
import { deleteAllCache, deleteCache, getCache, setCache } from "#app/utils/cache-client.ts";
import { useMatchesIndex } from "#app/utils/matches.ts";
import { initPubnub } from "#app/utils/pubnub.ts";
import { UserBasic } from "#app/utils/validation/auth-validation.ts";
import {
  UIMatch,
  useBeforeUnload,
  useFetcher,
  useParams,
  useRouteLoaderData,
} from "@remix-run/react";
import React from "react";
import { toast as showToast } from "sonner";
import { sendUserStatusToServer } from "./sent-status-user";
import Pubnub from "pubnub";
import ky from "ky";
import { getChannelType } from "#app/utils/messages/misc.messages";
import dayjs from "dayjs";
import "dayjs/locale/id";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

type SignalMessage = {
  actualChannel: string;
  publisher: string;
  message: any;
  timetoken: number;
  messageType?: string | number | undefined;
  uuid?: string | undefined;
};
// const mergeActionsIntoCachedData = (cachedData, newAction) => {
//   // Cari item dalam cachedData yang memiliki messageTimetoken sama dengan timetoken dari newAction
//   const item = cachedData.find((msg) => msg.messageTimetoken === newAction.messageTimetoken);
//
//   if (item) {
//     // Jika item ditemukan, periksa apakah sudah ada `actions` di dalamnya
//     if (!item.actions) {
//       item.actions = {}; // Buat objek `actions` jika belum ada
//     }
//
//     // Jika tipe action belum ada di dalam item, buat key baru
//     if (!item.actions[newAction.type]) {
//       item.actions[newAction.type] = {};
//     }
//
//     // Jika value belum ada di dalam tipe action, buat array baru untuk menyimpan action
//     if (!item.actions[newAction.type][newAction.value]) {
//       item.actions[newAction.type][newAction.value] = [
//         {
//           uuid: newAction.uuid,
//           actionTimetoken: newAction.actionTimetoken,
//         },
//       ];
//     } else {
//       // Jika sudah ada, perbarui data yang sudah ada
//       item.actions[newAction.type][newAction.value] = [
//         {
//           uuid: newAction.uuid,
//           actionTimetoken: newAction.actionTimetoken,
//         },
//       ];
//     }
//   }
//
//   // Kembalikan cachedData yang telah diperbarui
//   return cachedData;
// };

function validateChannel(channel: string): string {
  const pattern = /^(group\.[a-zA-Z0-9_]+|chat-group\.[a-zA-Z0-9_]+|private\.[a-zA-Z0-9_]+)$/;
  if (pattern.test(channel)) {
    return "Channel" + channel;
  }
}

type FetcherReload = (query: {
  [x: string]: string;
}) => void;

const ChildComponent = React.memo(
  function ChildComponent({
    user,
    match,
    fetcherList,
    fetcherMessages,
  }: {
    user: UserBasic;
    match: UIMatch<unknown, unknown> | undefined;
    fetcherList: FetcherReload;
    fetcherMessages: FetcherReload;
  }) {
    const pubnub = initPubnub(user);
    const targetChannel = match?.data?.roomId?.channel;

    async function userOnline(uuid) {
      const APIMETADATA = `/resources/user-active?uuid=${uuid}`;
      let targetPrivateUser = await ky.get(APIMETADATA).json();
      if (targetPrivateUser?.id) {
        const lastSeen = "Last seen " + dayjs(targetPrivateUser?.updated).fromNow();
        const status = targetPrivateUser?.active === "true" ? "ONLINE" : lastSeen;
        const statusElement = document.getElementById("status-user-chat");
        if (statusElement instanceof HTMLDivElement) {
          statusElement.innerHTML = status;
        }
      }
    }

    React.useEffect(() => {
      if (targetChannel) {
        const unsubscribe = initPubnubClient();

        return () => {
          unsubscribe();
        };
      }
    }, [targetChannel]);

    const initPubnubClient = () => {
      console.log(
        "%c[Join] channels " + targetChannel,
        "color: #ffffff; background-color: #4CAF50; font-weight: bold; padding: 2px 4px; border-radius: 3px;"
      );
      subscribeChannels();

      return () => {
        console.log(
          "%c[Leave] channels." + targetChannel,
          "color: #ffffff; background-color: #FF9800; font-style: italic; padding: 2px 4px; border-radius: 3px;"
        );
        unsubscribeChannels(subscribeChannels());
      };
    };

    const unsubscribeChannels = (listener) => {
      pubnub.removeListener(listener);
      pubnub.unsubscribe({
        channels: [targetChannel],
      });
    };

    const subscribeChannels = async () => {
      pubnub.subscribe({
        channels: [targetChannel],
        withPresence: true,
      });

      const listener: Pubnub.ListenerParameters = {
        signal(sig) {
          if (match) {
            if (
              sig.publisher !== user.uuid &&
              sig?.channel === (match?.data as { roomId: { channel: string } })?.roomId?.channel
            ) {
              const container = document.getElementById("container-typing-indicator");
              if (container instanceof HTMLDivElement) {
                if (sig.message === "typing_off") {
                  container.style.display = "none";
                } else {
                  container.style.display = "flex";
                }

                const typingIndicator = container.querySelector("#typing-indicator");
                if (typingIndicator instanceof HTMLDivElement) {
                  typingIndicator.innerHTML = sig.message;
                }
              }
            }
          }
        },
        async messageAction(act) {
          const query = {
            channel: act?.channel,
            invalidate: "true",
            scrool: "false",
          };
          fetcherMessages(query);
        },
        async message(msg: SignalMessage) {
          const waktu = msg.timetoken / 1e4;
          const notificationTime = new Date(waktu);
          const timeToast = notificationTime.toISOString();

          async function replaceText(data, user, fetcherList, fetcherMessages) {
            let room = data?.actualChannel;
            let message = data.message;

            const custom_data = parseIfString(message?.pn_gcm?.data?.custom_data);

            const firstName = custom_data?.author?.firstName ?? message?.author?.firstName ?? "";
            const lastName = custom_data?.author?.lastName ?? message?.author?.lastName ?? "";

            const fullName = lastName ? `${firstName} ${lastName}` : firstName;

            const metadata = message?.metadata;

            const chatCategory = metadata?.category;

            const isAdminSystem =
              chatCategory === "created_zoom" ||
              chatCategory === "removed_zoom" ||
              chatCategory === "created_group" ||
              chatCategory === "add_member" ||
              chatCategory === "leave_member" ||
              chatCategory === "removed_member";

            let parts = room?.split(".");
            let firstWord = parts ? parts[0] : null;
            let sent_to;
            let type_msg = message?.pn_apns?.aps?.alert?.body ?? "sent a message";

            switch (firstWord) {
              case "group":
                sent_to = type_msg + " in class chat";
                break;
              case "private":
                sent_to = type_msg;
                break;
              case "chat-group":
                sent_to = type_msg + " in group chat";
                break;
              default:
                sent_to = type_msg;
            }

            const cacheListKey = `user-data-list-chat-${user.id}`;
            const cacheListChat = (await getCache(cacheListKey)) as {
              data: { archive: any[]; class: any[]; chats: any[] };
            };
            const cacheMessagesKey = `data-messages-${data.actualChannel}-true`;
            const cacheMessages = (await getCache(cacheMessagesKey)) as {};

            const newMessage = {
              channel: data.actualChannel,
              timetoken: data.timetoken,
              message: data.message,
              messageType: -1,
              uuid: data.publisher,
            };

            if (cacheMessages) {
              const cachedData = cacheMessages?.messages;
              const newCacheData = [...(cachedData ?? []), newMessage];
              const _data = {
                ...cacheMessages,
                messages: newCacheData,
              };
              await setCache(cacheMessagesKey, _data);

              const query = { channel: data.actualChannel };
              fetcherMessages(query);
            } else {
              const query = { channel: data.actualChannel };
              fetcherMessages(query);
            }

            if (cacheListChat) {
              async function assignMessageToAll(
                data: any,
                msg: { publisher: string; channel: string },
                msgText: string,
                timetoken: string
              ) {
                const types = ["archive", "chats", "class"] as const;
                let found = false;

                for (const type of types) {
                  const index = data[type].findIndex((item: any) => item.channel === msg.channel);

                  if (index !== -1) {
                    // Assign `message` jika item ditemukan
                    data[type][index].message = msgText;
                    data[type][index].timetoken = timetoken;
                    if (msg.publisher !== user?.uuid) {
                      data[type][index].unread = data[type][index].unread + 1;
                    }

                    data[type] = data[type]
                      .map((item: any) => ({
                        ...item,
                        sortTime: item.timetoken
                          ? (parseTimetoken(item.timetoken) as string)
                          : (item.createdDate as string),
                      }))
                      .sort(
                        (a, b) =>
                          (new Date(b.sortTime).getTime() as number) -
                          (new Date(a.sortTime).getTime() as number)
                      );

                    found = true;

                    const newData = { data: data };
                    console.warn(
                      "DEBUGPRINT[9]: pubnub-main.tsx:300: cacheListKey=",
                      cacheListKey,
                      data
                    );
                    await setCache(cacheListKey, newData);

                    break; // Keluar dari loop setelah menemukan dan meng-assign message
                  }
                }

                if (!found) {
                  await deleteCache(cacheListKey);
                }

                const query = { userId: user?.id as string };
                console.warn("JALAN RELOAD TAB");
                fetcherList(query);
              }

              const cachedData = cacheListChat.data;
              const messageText =
                custom_data?.text || custom_data?.name || message?.metadata?.title;

              assignMessageToAll(cachedData, data, messageText, data.timetoken.toString());
            } else {
              const query = { userId: user?.id as string };
              fetcherList(query);
            }

            const shoudShow =
              data?.actualChannel !==
              (match?.data as { roomId: { channel: string } })?.roomId?.channel;

            const shoudRead =
              data?.actualChannel ===
              (match?.data as { roomId: { channel: string } })?.roomId?.channel;

            console.warn("DEBUGPRINT[3]: pubnub-main.tsx:346: data=", data);
            if (data.publisher !== user.uuid && shoudShow) {
              setTimeout(() => {
                showToast.custom((t) => (
                  <div
                    className={`max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex`}
                  >
                    <div className="flex flex-1 items-center  rounded-lg px-3 py-1 cursor-pointer">
                      <div className="relative w-14 h-14 flex flex-shrink-0 items-end">
                        <img
                          width={60}
                          height={60}
                          src={custom_data?.author?.imageUrl || `/static/images/logokedi.svg`}
                          className="absolute inset-0 h-full w-full object-cover rounded-xl shadow-md border border-gray-300"
                          alt=""
                        />
                        <span className="absolute h-4 w-4 bg-green-400 rounded-full bottom-0 right-0 border-2 border-white" />
                      </div>
                      <div className="ml-3">
                        <span className="font-semibold tracking-tight text-xs">{fullName}</span>{" "}
                        <span className="text-xs leading-none opacity-50">{sent_to}</span>
                        <p className="text-[13px] leading-4 pt-2 italic opacity-70">
                          {" "}
                          {custom_data?.text || custom_data?.name || message?.metadata?.title}{" "}
                          {isAdminSystem ? message?.text : ""}
                        </p>
                        <span className="text-[10px] text-blue-500 font-medium leading-4 opacity-75">
                          a few seconds ago
                        </span>
                      </div>
                    </div>
                    <div className="flex border-l border-gray-200">
                      <button
                        onClick={() => {
                          showToast.dismiss(t);
                        }}
                        className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                ));
              }, 0);
            }
          }

          replaceText(msg, user, fetcherList, fetcherMessages);

          if (match) {
            typingTimer = setTimeout(() => {
              // fetcherList.submit(
              // 	{ userId: user?.uuid },
              // 	{ method: "get", action: "/resources/channels" },
              // );
              // fetcherMessages.submit(
              // 	{ channel: msg.actualChannel, invalidate: "true" },
              // 	{ method: "get", action: "/resources/messages" },
              // );
            }, 100);
          }

          //
          // const payload = {
          // 	intent: "new-message",
          // 	message: JSON.stringify(message),
          // };
          //
          // fetcher.submit(payload, {
          // 	method: "post",
          // 	action: match?.pathname,
          // });
          //console.log(msg);
          // if (msg.publisher !== user.uuid) {
          // 	revalidator.revalidate();
          // }
          //setNotifCall(msg);
        },
        presence(p) {
          if (validateChannel(p.channel)) {
            if (match?.data?.roomId?.channel === p.channel) {
              const channelType = getChannelType(p?.channel);
              if (channelType === "private") {
                userOnline(match?.data?.userChatAktif?.receiver.id);
              }
            }
          }
        },
      };
      pubnub.addListener(listener);
      return listener;
    };

    return <div className="hidden">PUBNUB</div>;
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.match) === JSON.stringify(nextProps.match);
  }
);

const MainComponent = React.memo(
  function MainComponent({
    user,
  }: {
    user: UserBasic;
  }) {
    const pubnub = initPubnub(user);

    async function localUser(user: UserBasic) {
      const basicUserKey = "user-data-basic";
      const userBasic = await getCache(basicUserKey);
      const { publishKey, subscribeKey, ...basicData } = user;
      if (userBasic?.id !== user.id) {
        await deleteAllCache();
        await setCache(basicUserKey, basicData);
      } else {
        await setCache(basicUserKey, basicData);
      }
    }

    useBeforeUnload(
      React.useCallback(() => {
        console.log("Unsubscribed from channels - cb");
        unsubscribeChannels(subscribeChannels());
        sendUserStatusToServer(user, false);
        const time = new Date(Date.now());
        localStorage.setItem(
          "log-unsubscribe",
          JSON.stringify("Unsubscribed from channels " + time)
        );
      }, [])
    );

    React.useEffect(() => {
      const unsubscribe = initPubnubClient();

      return () => {
        unsubscribe();
      };
    }, []);

    React.useEffect(() => {
      localUser(user);
      sendUserStatusToServer(user, true);
    }, []);

    const initPubnubClient = () => {
      console.log(
        "%c[Chat Ready] Successfully subscribed to wildcard channels.",
        "color: #ffffff; background-color: #4CAF50; font-weight: bold; padding: 2px 4px; border-radius: 3px;"
      );
      subscribeChannels();

      return () => {
        console.log(
          "%c[Navigation] Unsubscribed from wildcard channels.",
          "color: #ffffff; background-color: #FF9800; font-style: italic; padding: 2px 4px; border-radius: 3px;"
        );
        unsubscribeChannels(subscribeChannels());
      };
    };

    const unsubscribeChannels = (listener) => {
      pubnub.removeListener(listener);
      pubnub.unsubscribe({
        channels: ["private.*", "group.*", "chat-group.*"],
      });
    };

    const subscribeChannels = async () => {
      pubnub.subscribe({
        channels: ["private.*", "group.*", "chat-group.*"],
        withPresence: true,
      });

      // const userUUID = await pubnub.objects.getUUIDMetadata();

      const listener: Pubnub.ListenerParameters = {
        async message(msg: SignalMessage) {
          // console.warn("MESSAGE From Wilcard Sub", msg);
        },
        // signal(sig) {
        //   console.warn("SIGNAL From Wilcard Sub", sig);
        // },
        // async messageAction(act) {
        //   console.warn("ACTION From Wilcard Sub", act);
        // },
        // presence(p) {
        //   console.warn("PRESENCE From Wilcard Sub", p);
        // },
        // objects: (objectEvent) => {
        //   console.warn("OBJEvent From Wilcard Sub", objectEvent);
        // },
        // status(s) {
        //   console.warn("STATUS EVENT From Wilcard Sub", s);
        // },
      };
      pubnub.addListener(listener);
      return listener;
    };

    return <div className="hidden">PUBNUB</div>;
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.user) === JSON.stringify(nextProps.user);
  }
);

function useReloadData({ key, action }: { key: string; action: string }) {
  const fetcher = useFetcher({ key });
  const submit = React.useCallback(
    (query: {
      [x: string]: string;
    }) => {
      fetcher.submit(query, { method: "get", action });
    },
    [fetcher]
  );
  return submit;
}

export const PubNubMain = React.memo(() => {
  const { user } = useRouteLoaderData<typeof RootLoader>("root") || {};
  const match = useMatchesIndex(2);

  const fetcherList = useReloadData({
    key: "list-chat",
    action: "/resources/channels",
  });

  const fetcherMessages = useReloadData({
    key: (match?.data as { roomId: { channel: string } })?.roomId?.channel,
    action: "/resources/messages",
  });

  if (!user) return <div className="hidden" />;

  return (
    <div className="hidden">
      <button onClick={() => fetcherList({ userId: user?.id as string })}>LOAD LIST</button>
      <button
        onClick={() =>
          fetcherMessages({
            channel: match?.data?.roomId?.channel,
            invalidate: "true",
            scrool: "false",
          })
        }
      >
        LOAD MESSAGE
      </button>
      <ChildComponent
        user={user}
        match={match}
        fetcherList={fetcherList}
        fetcherMessages={fetcherMessages}
      />
      <MainComponent user={user} />
    </div>
  );
});
