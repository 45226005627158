import React, { useState, useEffect } from "react";
import { ClientOnly } from "remix-utils/client-only";
import { Console, Hook, Unhook } from "console-feed";
import { Icon } from "#app/components/ui/icon";

const DebugLog = () => {
  const [logs, setLogs] = useState([]);

  // run once!
  useEffect(() => {
    const hookedConsole = Hook(
      window.console,
      (log) => setLogs((currLogs) => [...currLogs, log]),
      false
    );
    return () => Unhook(hookedConsole);
  }, []);

  return (
    <details
      className="fixed top-2 left-2  z-50 bg-gray-900 rounded-sm group [&_summary::-webkit-details-marker]:hidden"
      open
    >
      <summary className="flex cursor-pointer items-center gap-1.5 rounded-sm border w-fit py-1 px-2 bg-red-600 text-white m-2">
        <span className="font-bold text-xs">DEBUG </span>
        <Icon
          name="plus"
          size="md"
          className="shrink-0 transition duration-300 group-open:-rotate-45 font-bold"
        />
      </summary>
      <div className=" max-h-[93vh] overflow-y-auto border border-2 border-red-700 p-2">
        <Console logs={logs} variant="light" />
      </div>
    </details>
  );
};

export { DebugLog };
